import React from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";
import Font from 'react-font'

const MyTitleMessage = styled.h1`
  position: absolute;
  width: 100%;
  top: 40%;
  bottom:90%;
  z-index: 1;
  left:00%;
  text-align: center;
  strong {
    font-size: 1.25em;
    font-weight: 800;
  }
  div {
    color: ${props => props.theme.textColor};

    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    letter-spacing: 7px;

    .main {
      font-size: 50px;
    }

    .sub {
      font-size: 27px;
      letter-spacing: 2px;
    }
  }
  @media screen and (max-width: 400px) {
    .titleMessage {
      position:relative;
     top:-3em
    }
  }
`;

const TitleMessage = () => (
  <MyTitleMessage>
    <div className="titleMessage">
      <div className="heading">
        <div className="main text-center mb-3">

          <br />
          <span>
            <strong>Dhruv Agnihotri</strong><br />
            <Font family='Montserrat' italic weight={400}>Lead Software Engineer @ Salesforce</Font>
          </span>
        </div>
        <div className="sub">
          <Typewriter
            options={{
              strings: [ "Machine Learning", "Big Data", "Salesforce Developer", "Python Enthusiast", "Full Stack"],
              autoStart: true,
              loop: true,
              delay: 40,
              deleteSpeed:10
            }}
            // onInit={(typewriter)=> {
  
            //   typewriter
            //   .changeDelay(40)
            //   .typeString("Python Enthusiast")
            //   .pauseFor(1000)
            //   .deleteAll()
            //   .typeString("Machine Learning Engineer")
            //   .pauseFor(1000)
            //   .deleteAll()
            //   .typeString("Big Data")
            //   .pauseFor(1000)
            //   .deleteAll()
            //   .typeString("Full Stack")
            //   .pauseFor(1000)
            //   .deleteAll()
            //   .start();
            //   }}
          />
        </div>
      </div>
    </div>
  </MyTitleMessage>
);

export default TitleMessage;
