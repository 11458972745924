import React, { useEffect, lazy, Suspense } from "react";
import MyNavbar from "./components/my-navbar/my-navbar.component";
import MyCarousal from "./components/my-carousal/my-carousal.component";
import Container from "react-bootstrap/Container";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { Parallax } from "react-parallax";
import posthog from 'posthog-js';
import './App.css';
import {} from 'dotenv/config';
// Images
import ObjectDetectionImage from "./assets/img/projects/object-detection-image.jpg";
import EmotionRecognitionImage from "./assets/img/projects/emotion-recognition.jpg";
import GenAIImage from "./assets/img/projects/GenAI4.png";
import AITradingBot from "./assets/img/projects/AITradingBot.jpg";

// Lazy loaded components
const ContactMe = lazy(() => import("./pages/contact-me/contact-me.component"));
const About = lazy(() => import("./pages/about/about.component"));
const Skills = lazy(() => import("./pages/skills/skills.component"));
const ContactForm = lazy(() => import("./pages/contact-form/contact-form.component"));
const ProfileItem = lazy(() => import("./components/project-item/project-item.component"));
const ExperienceConsolidated = lazy(() => import("./components/experience-timeline/experience-consolidated/experience-consolidated.component"));
// const ScheduleMeeting = lazy(() => import("./components/ScheduleMeeting/ScheduleMeeting.component"));
const ObjectDetection = lazy(() => import("./components/project-item/project-specific-content/object-detection/object-detection-yolov3.component"));
const EmotionDetection = lazy(() => import("./components/project-item/project-specific-content/emotion-recognition/emotion-recognition.component"));
const AIImageGenerator = lazy(() => import("./components/project-item/project-specific-content/ai-image-generator/ai-image-generator.component"));
const TradingBot = lazy(() => import("./components/project-item/project-specific-content/trading-bot/trading-bot.component"));


require('dotenv').config();

// Initialize PostHog
posthog.init('phc_WRph2WT7OEKMQAOMuA3tAeOcS3Dc6KLK3tQxSgbre7u', {
  api_host: 'https://app.posthog.com',
  autocapture: true,
  capture_pageview: true,
  persistence: 'localStorage', // Optional: use local storage for persistence
});

// Custom hook to track page views
const usePageTracking = () => {
  useEffect(() => {
    const handleRouteChange = () => {
      posthog.capture('$pageview');
    };
    window.addEventListener('popstate', handleRouteChange);
    handleRouteChange();

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);
};

const App = () => {
  usePageTracking();

  const handleButtonClick = (buttonName) => {
    posthog.capture('button_click', { button_name: buttonName });
  };

  const handleFormSubmit = (formName) => {
    posthog.capture('form_submit', { form_name: formName });
  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <MyCarousal />
      <MyNavbar />
      <Suspense fallback={<div>Loading...</div>}>
        <div>
          <Parallax
            blur={{ min: -30, max: 30 }}
            bgImage={require("./assets/img/parallex/background.webp")}
            bgImageAlt=""
            strength={-200}
          >
            <div>
              <Container className="container-box rounded">
                <Fade duration={500}>
                  <About />
                </Fade>
              </Container>
            </div>
          </Parallax>
        </div>
        <hr />
        <Container className="container-box rounded container ProjectOverflow" id='Personalprojects'>
          <h1 className="pt-3 text-center font-details-b pb-3"> Personal Projects</h1><br />
          <div className='rowForProjects'>
            <ProfileItem 
              backgroundImage={GenAIImage} 
              project='Text To Image' 
              projectDescription='Image generation using stable diffusion models' 
              projectComponent={<AIImageGenerator />} 
              onClick={() => handleButtonClick('Text To Image Project')}
            />
            <ProfileItem 
              backgroundImage={ObjectDetectionImage} 
              project='Object Detection' 
              projectDescription='Object Detection using YOLOV3' 
              projectComponent={<ObjectDetection />} 
              onClick={() => handleButtonClick('Object Detection Project')}
            />
            <ProfileItem 
              backgroundImage={EmotionRecognitionImage} 
              project='Emotion Recognition' 
              projectDescription='Emotion Recognition using Deep net' 
              projectComponent={<EmotionDetection />} 
              onClick={() => handleButtonClick('Emotion Recognition Project')}
            />
            <ProfileItem 
              backgroundImage={AITradingBot} 
              project='AI Trading Bot' 
              projectDescription='Intelligent bot that can make trades on robinhood' 
              projectComponent={<TradingBot />} 
              onClick={() => handleButtonClick('AI Trading Bot Project')}
            />
          </div>
        </Container>
        <Container className="container-box rounded">
          <Slide bottom duration={500}>
            <hr />
            <Skills />
          </Slide>
        </Container>
        <Container className="container-box rounded container ProjectOverflow" id='experienceTimeline'>
          <div>
            <hr />
            <h1 className="pt-3 text-center font-details-b pb-3">Experience</h1><br />
            <ExperienceConsolidated />
          </div>
        </Container>
        <Container className="container-box rounded">
          <Fade duration={500}>
            <hr />
            <ContactMe onClick={() => handleButtonClick('Contact Me')} />
          </Fade>
        </Container>
        <Container className="container-box rounded">
          <Fade duration={500}>
            <hr />
            <ContactForm onSubmit={() => handleFormSubmit('Contact Form')} />
          </Fade>
        </Container>
        <hr />
      </Suspense>
    </div>
  );
};

export default App;
